import useFormContext from "../hooks/useFormContext";
import Input from "./Input";
import PhoneNumberInput from "./PhoneNumberInput";
import RadioButtonInput from "./RadioButtonInput";

const InitialInformation = () => {
  const {
    data,
    handleChange,
    handlePhoneNumberChange,
    handleRadioButtonChange,
    resolveLanguageName,
    messages,
    languages,
    i18n,
    t,
  } = useFormContext();

  const fields = [
    {
      labelText: t("language"),
      labelFor: "language",
      id: "language",
      name: "language",
      type: "radio",
      autoComplete: "email",
      isRequired: true,
      isLanguageSelector: true,
      placeholder: t("languagePlaceholder"),
    },
    {
      labelText: t("emailAddress"),
      labelFor: "emailAddress",
      id: "emailAddress",
      name: "emailAddress",
      type: "email",
      autoComplete: "email",
      isRequired: true,
      placeholder: t("emailAddressPlaceholder"),
    },
    {
      labelText: t("mobileNumber"),
      labelFor: "mobileNumber",
      id: "mobileNumber",
      name: "mobileNumber",
      type: "text",
      autoComplete: "mobileNumber",
      isRequired: true,
      placeholder: t("mobileNumberPlaceholder"),
      isPhoneNumberInput: true,
    },
    {
      labelText: t("firstName"),
      labelFor: "firstName",
      id: "firstName",
      name: "firstName",
      type: "text",
      isRequired: true,
      placeholder: t("firstNamePlaceholder"),
    },
    {
      labelText: t("lastName"),
      labelFor: "lastName",
      id: "lastName",
      name: "lastName",
      type: "text",
      isRequired: true,
      placeholder: t("lastNamePlaceholder"),
    },
  ];

  const content = (
    <>
      {messages && (
        <>
          {messages.map((message, index) => {
            return (
              <>
                <div
                  id={`alert-${index}`}
                  className="flex p-4 mb-4 bg-yellow-100 rounded-lg dark:bg-yellow-200"
                  role="alert"
                >
                  <svg
                    aria-hidden="true"
                    className="flex-shrink-0 w-5 h-5 text-yellow-700 dark:text-yellow-800"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>

                  <span className="sr-only">Info</span>

                  <div className="ml-3 text-sm font-medium text-yellow-700 dark:text-yellow-800">
                    {message}
                  </div>
                  <button
                    type="button"
                    className="ml-auto -mx-1.5 -my-1.5 bg-yellow-100 text-yellow-500 rounded-lg focus:ring-2 focus:ring-yellow-400 p-1.5 hover:bg-yellow-200 inline-flex h-8 w-8 dark:bg-yellow-200 dark:text-yellow-600 dark:hover:bg-yellow-300"
                    data-dismiss-target={`#alert-${index}`}
                    aria-label="Close"
                  >
                    <span className="sr-only">Close</span>
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
              </>
            );
          }, messages)}
        </>
      )}

      <div className="flex flex-col space-y-3">
        {fields.map((field) => {
          if (field.isPhoneNumberInput)
            return (
              <PhoneNumberInput
                key={field.id}
                handlePhoneNumberChange={handlePhoneNumberChange}
                value={data[field.id]}
                labelText={field.labelText}
                labelFor={field.labelFor}
                id={field.id}
                name={field.name}
                type={field.type}
                isRequired={field.isRequired}
                isPhoneNumberInput={field.isPhoneNumberInput}
                placeholder={field.placeholder}
              />
            );
          else if (field.isLanguageSelector)
            return (
              <div className="flex">
                {languages
                  // .sort((a, b) => a > b)
                  .map((data) => {
                    return (
                      <>
                        <div className="grow">
                          <RadioButtonInput
                            id={field.id}
                            value={data}
                            label={data}
                            i18n={i18n}
                            resolveLanguageName={resolveLanguageName}
                            handleRadioButtonChange={handleRadioButtonChange}
                          />
                        </div>
                      </>
                    );
                  })}
              </div>
            );
          else
            return (
              <Input
                key={field.id}
                handleChange={handleChange}
                value={data[field.id]}
                labelText={field.labelText}
                labelFor={field.labelFor}
                id={field.id}
                name={field.name}
                type={field.type}
                isRequired={field.isRequired}
                isPhoneNumberInput={field.isPhoneNumberInput}
                placeholder={field.placeholder}
              />
            );
        })}
      </div>
    </>
  );

  return content;
};

export default InitialInformation;
