import Header from "../components/Header";
import InitialInformation from "../components/InitialInformation";
import useFormContext from "../hooks/useFormContext";

export default function InitialInformationPage() {
  const { t } = useFormContext();

  return (
    <>
      <Header
        heading={t("startYourKycJourney")}
        paragraph={t("activateAndPurchaseCryptoToday")}
      />

      <InitialInformation />
    </>
  );
}
