import Form from "./components/Form";
import { FormProvider } from "./context/FormContext";

function App() {
  return (
    <>
      <div className="relative flex flex-col justify-center min-h-screen m-2">
        <div className="w-full lg:p-4 m-auto bg-white rounded-md lg:max-w-xl md:absolute md:inset-x-0 md:top-0 md:h-16 md:absolute md:inset-x-0 md:top-0 md:h-16">
          <FormProvider>
            <Form />
          </FormProvider>
        </div>
      </div>
    </>
  );
}

export default App;
