import FormInputs from "./FormInputs";
import useFormContext from "../hooks/useFormContext";
import configData from "../config.json";

const Form = () => {
  const {
    page,
    setPage,
    data,
    canSubmit,
    submitHide,
    setAccessToken,
    setMessages,
    t,
  } = useFormContext();

  const handleSubmit = async (e) => {
    e.preventDefault();

    let accessToken = null;

    try {
      let res = await fetch(configData.API_TOKEN_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          firstName: data.firstName,
          lastName: data.lastName,
          mobileNumber: data.mobileNumber,
          emailAddress: data.emailAddress,
        }),
      });

      let resJson = await res.json();

      if (res.status === 200) {
        // JSON Payload in Response
        accessToken = resJson.access_token;

        setAccessToken((token) => accessToken);
        setPage((prev) => prev + 1);
      } else {
        if (res.status === 409) {
          setMessages([
            "KYC applicant conflict, you've already requested access.  If you are having issues, please contact support@badgercoin.com",
          ]);
        } else {
          setMessages([
            "Issue communicating with the backend, please try again later.",
          ]);
        }
      }
    } catch (err) {
      if (err.status === 409) {
        setMessages([
          "KYC applicant conflict, you've already requested access.  If you are having issues, please contact support@badgercoin.com",
        ]);
      } else {
        setMessages([
          "Issue communicating with the backend, please try again later.",
        ]);
      }
    }
  };

  const content = (
    <>
      <form className="mt-6" onSubmit={handleSubmit}>
        <FormInputs />

        {page !== 1 && (
          <>
            <div className="relative flex items-center justify-center w-full mt-6 border border-t">
              <div className="absolute px-5 bg-white"></div>
            </div>

            <div className="mt-6">
              <button
                type="submit"
                className={`w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform rounded-md focus:outline-none ${submitHide}`}
                disabled={!canSubmit}
              >
                {t("continueButton")}
              </button>
            </div>
          </>
        )}
      </form>
    </>
  );

  return content;
};
export default Form;
