import { createContext, useState } from "react";
import { useTranslation } from "react-i18next";

const FormContext = createContext({});

export const FormProvider = ({ children }) => {
  const title = {
    0: "Initial Information",
    1: "KYC",
  };

  const { t, i18n } = useTranslation();

  const [languages, setLanguages] = useState(i18n.languages);
  const [messages, setMessages] = useState([]);
  const [page, setPage] = useState(0);

  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    emailAddress: "",
  });

  const [accessToken, setAccessToken] = useState();

  const handleChange = (e) => {
    const type = e.target.type;
    const name = e.target.name;
    const value = type === "checkbox" ? e.target.checked : e.target.value;

    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePhoneNumberChange = (value, country, e, formattedValue) => {
    setData((prevData) => ({
      ...prevData,
      mobileNumber: value,
    }));
  };

  const handleRadioButtonChange = (e) => {
    return i18n.changeLanguage(e.target.value);
  };

  const resolveLanguageName = (value) => {
    return t(value);
  };

  const { ...requiredInputs } = data;

  // We can submit when all fields have been filled out
  const canSubmit = [...Object.values(requiredInputs)].every(Boolean); // && page === Object.keys(title).length - 1;

  const canNextPage1 = Object.keys(data)
    .filter((key) => key.startsWith("bill") && key !== "billAddress2")
    .map((key) => data[key])
    .every(Boolean);

  const canNextPage2 = Object.keys(data)
    .filter((key) => key.startsWith("ship") && key !== "shipAddress2")
    .map((key) => data[key])
    .every(Boolean);

  const disablePrev = page === 0;

  const disableNext =
    page === Object.keys(title).length - 1 ||
    (page === 0 && !canNextPage1) ||
    (page === 1 && !canNextPage2);

  const prevHide = page === 0 && "remove-button";
  const nextHide = page === Object.keys(title).length - 1 && "remove-button";

  //
  const submitHide = canSubmit
    ? "bg-sky-700 hover:bg-sky-600 focus:bg-sky-600"
    : "bg-gray-700 hover:bg-gray-700 focus:bg-gray-700";

  return (
    <FormContext.Provider
      value={{
        title,
        page,
        setPage,
        data,
        setData,
        canSubmit,
        handleChange,
        handlePhoneNumberChange,
        handleRadioButtonChange,
        resolveLanguageName,
        disablePrev,
        disableNext,
        prevHide,
        nextHide,
        submitHide,
        accessToken,
        setAccessToken,
        setLanguages,
        languages,
        setMessages,
        messages,
        t,
        i18n,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export default FormContext;
