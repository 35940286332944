import InitialInformationPage from "../pages/InitialInformationPage";
import KnowYourCustomer from "../pages/KnowYourCustomerPage";
import useFormContext from "../hooks/useFormContext";

const FormInputs = () => {
  const { page } = useFormContext();

  const display = {
    0: <InitialInformationPage />,
    1: <KnowYourCustomer />,
  };

  const content = <div className="form-inputs flex-col">{display[page]}</div>;

  return content;
};

export default FormInputs;
