import Header from "../components/Header";
import KnowYourCustomer from "../components/KnowYourCustomer";
import useFormContext from "../hooks/useFormContext";

export default function KnowYourCustomerPage() {
  const { t } = useFormContext();

  return (
    <>
      <Header heading={t("confirmYourIdentity")} />

      <KnowYourCustomer />
    </>
  );
}
