import React from "react";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const fixedInputClass =
  "rounded-md appearance-none relative block w-full border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10 sm:text-sm";

export default function PhoneNumberInput({
  handlePhoneNumberChange,
  id,
  name,
  value,
  labelText,
  labelFor,
  isRequired = false,
}) {
  return (
    <div>
      <label htmlFor={labelFor} className="sr-only">
        {labelText}
      </label>
      <PhoneInput
        country={"ca"}
        onlyCountries={["ca"]}
        onChange={handlePhoneNumberChange}
        disableCountryCode={true}
        id={id}
        name={name}
        value={value}
        inputStyle={{
          fontSize: 16 + "px",
          width: 100 + "%",
        }}
        required={isRequired}
        className={fixedInputClass}
        placeholder="(555) 555-1234"
        masks={{ ca: "(...) ...-...." }}
      />
    </div>
  );
}
