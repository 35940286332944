import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  en: {
    translation: {
      en: "English",
      fr: "French",
      language: "Language",
      languagePlaceholder: "Language",
      emailAddress: "Email address",
      emailAddressPlaceholder: "Email address",
      firstName: "First name",
      firstNamePlaceholder: "First Name",
      lastName: "Last name",
      lastNamePlaceholder: "Last Name",
      mobileNumber: "Mobile number",
      mobileNumberPlaceholder: "(NXX) NXX-XXXX",
      startYourKycJourney: "Begin your KYC journey",
      activateAndPurchaseCryptoToday: "Activate and purchase crypto today",
      confirmYourIdentity: "Confirm your identity",
      continueButton: "Continue",
    },
  },
  fr: {
    translation: {
      en: "Anglais",
      fr: "Français",
      language: "Langue",
      languagePlaceholder: "Language",
      emailAddress: "Adresse courriel",
      emailAddressPlaceholder: "Adresse courriel",
      firstName: "Prénom",
      firstNamePlaceholder: "Prénom",
      lastName: "Nom de famille",
      lastNamePlaceholder: "Nom de famille",
      mobileNumber: "Téléphone mobile",
      mobileNumberPlaceholder: "(NXX) NXX-XXXX",
      startYourKycJourney: "Démarrez votre KYC",
      activateAndPurchaseCryptoToday:
        "Activez votre KYC et achetez votre crypto aujourd'hui",
      confirmYourIdentity: "Confirme ton identité",
      continueButton: "Continuer",
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    debug: true,
    fallbackLng: ["en", "fr"],
    supportedLngs: ["en", "fr"],
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

console.log(i18n.languages);

export default i18n;
