import useFormContext from "../hooks/useFormContext";
import SumsubWebSdk from "@sumsub/websdk-react";
import configData from "../config.json";

const KnowYourCustomer = () => {
  const { setPage, handleChange, accessToken, i18n } = useFormContext();

  const content = (
    <SumsubWebSdk
      accessToken={accessToken}
      updatedAccessToken={() => handleChange(accessToken)}
      expirationHandler={() => Promise.resolve(accessToken)}
      config={{
        lang: i18n.language,
        uiConf: {
          customCss: configData.CSS_OVERRIDE_URL,
        },
        onMessage: (type, payload) => {
          console.log("WebSDK onMessage", type, payload);
          setPage(() => {});
        },
        onError: (error) => {
          console.error("WebSDK onError", error);
        },
      }}
      options={{
        addViewportTag: true,
        adaptIframeHeight: true,
      }}
    />
  );

  return content;
};

export default KnowYourCustomer;
